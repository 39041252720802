/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}
body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

:root {
    --primary-color: #153d77;
    --secondary-color: #6c757d;
    --white-color: #fff;
    --success-color: #198754;
    --delete-color: #dc3545;
    --background-color: #f2f2f2;
}

.success-snackbar {
    --mat-mdc-snack-bar-button-color: var(--white-color);
    --mdc-snackbar-container-color: var(--success-color);
    --mdc-snackbar-supporting-text-color: var(--white-color);
}

.info-snackbar {
    --mat-mdc-snack-bar-button-color: var(--white-color);
    --mdc-snackbar-container-color: var(--primary-color);
    --mdc-snackbar-supporting-text-color: var(--white-color);
}

.error-snackbar {
    --mat-mdc-snack-bar-button-color: var(--white-color);
    --mdc-snackbar-container-color: var(--delete-color);
    --mdc-snackbar-supporting-text-color: var(--white-color);
}

fieldset {
    border-width: 2px;
    border-style: solid;
    border-color: lightgrey;
    border-radius: 10px
}

.table-container {
    width: 100%;
    overflow: auto;
}

.horizontal-scroll {
    width: 800px;
}
